// Import 3rd party libraries
import $ from "jquery";
import "slick-slider/slick/slick.min.js";

// Import css
import "slick-slider/slick/slick.css";

(() => {
    $('#video-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        asNavFor: '#title-slider',
        speed: 500,
        arrows: false,
        dots: false,
        lazyLoad: 'ondemand',
    });

    $('#title-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '#video-slider',
        focusOnSelect: true,
        arrows: true,
        dots: false,
        prevArrow: '<button type="button" class="slick-prev" title="go to previous slide"><i class="fa-solid fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next" title="go to next slide"><i class="fa-solid fa-chevron-right"></i></button>',
    });
})()